// Import Styles
import '../sass/styles.scss';

// Import Plugins
import 'bootstrap';
import 'jquery-match-height';

(function () { 

    $(document).ready(function() 
    {
        $('.js-hamburger').on('click', function(e) {
            e.preventDefault();
            $(this).toggleClass('is-active');
            $('.js-header__navigation').toggleClass('is-active');
            $('body').toggleClass('is-active');
        });
    });

    $(window).scroll(function() 
    {
        
    });

})();